import { Box, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const HeaderContainer = styled(Box)((props) => {
  return {
    padding: 20,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
    backgroundColor: props.backgroundColor ? props.backgroundColor : "white",
    zIndex: 10000,
  };
});
const IconBtn = styled(ArrowBackIcon)({
  marginRight: 6,
});
const Title = styled(Typography)((props) => {
  return {
    font: props?.font ? props?.font : "normal bold  20px/24px Lato !important",
    // fontWeight: "bold !important",
  };
});

export function FormTopbar(props) {
  const { label, listPath, disabled } = props;
  const navigate = useNavigate();

  return (
    <HeaderContainer backgroundColor={`${props?.backgroundColor || ""}`}>
      <IconButton
        onClick={() => navigate(listPath ? listPath : -1)}
        disabled={disabled}
      >
        <IconBtn color="primary" />
      </IconButton>
      <Title font={props?.font}>{label}</Title>
    </HeaderContainer>
  );
}