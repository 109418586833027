import {
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
  } from "@mui/material";
  import { Box } from "@mui/material";
  export const CustomSelectField = (props) => {
    const {
      label,
      variant,
      inputValues,
      name,
      onChange,
      onBlur,
      value,
      className,
      style,
      fieldStyle,
      error,
      isViewMode,
      disabled,
      formik,
    } = props;
  
    return (
      <>
        <Box style={style}>
          <FormControl
            fullWidth
            error={
              error
                ? error
                : (formik?.touched?.[name] && formik?.errors?.[name]) || ""
            }
          >
            <InputLabel id="demo-simple-select-error-label">{label}</InputLabel>
            <Select
              labelid="demo-simple-select-error-label"
              id="demo-simple-select"
              value={value || ""}
              label={label}
              onChange={onChange}
              variant={variant || "outlined"}
              fullWidth
              name={name}
              onBlur={onBlur}
              error={error || (formik?.touched?.[name] && formik?.errors?.[name])}
              helperText={
                error ||
                (formik?.touched?.[name] && formik?.errors?.[name]
                  ? formik?.errors?.[name]
                  : "")
              }
              className={className}
              style={fieldStyle}
              inputProps={{
                readOnly: isViewMode,
                disabled: Boolean(disabled),
              }}
            >
              {inputValues?.map((option, index) => (
                <MenuItem
                  key={index}
                  value={
                    option?.accessor
                      ? option[option?.accessor]
                      : option.id || option.value
                  }
                >
                  {option.name || option.label || "No data Found"}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>
              {error || (formik?.touched?.[name] && formik?.errors?.[name])}
            </FormHelperText>
          </FormControl>
        </Box>
      </>
    );
  };
  