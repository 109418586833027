import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { adminRoutes, nonAdminRoutes } from "../../routes/routes";
import LoginForm from "../pages/login/LoginForm";
import { Layout } from "./Layout";
import Cookies from "universal-cookie";
import { ROUTE_PATHS } from "../../routes/routePath";

const ProtectedRoute = () => {
  const cookies = new Cookies();
  const isAdmin = cookies.get("isAdmin");

  return (
    <Routes>
      <Route path="/login" element={<LoginForm />}></Route>
      <Route
        path="/"
        element={
          isAdmin !== undefined ? (
            <Layout />
          ) : (
            <Navigate to={ROUTE_PATHS.LOGIN_SCREEN} />
          ) // to protect route
        }
      >
        {isAdmin === "true" ? (
          adminRoutes.map(({ path, Component, exact }) => {
            return (
              <Route
                path={path}
                element={<Component />}
                exact={exact}
                key={path}
              />
            );
          })
        ) : isAdmin === "false" ? (
          nonAdminRoutes.map(({ path, Component, exact }) => {
            return (
              <Route
                path={path}
                element={<Component />}
                exact={exact}
                key={path}
              />
            );
          })
        ) : (
          <Route
            element={<Navigate to={ROUTE_PATHS.LOGIN_SCREEN} />}
            path="*"
          />
        )}
      </Route>
    </Routes>
  );
};

export default ProtectedRoute;
