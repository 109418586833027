import MyLocationIcon from "@mui/icons-material/MyLocation";
import IconButton from "@mui/material/IconButton";
import { filterCamaSepratorSeeds } from "../validation/filterTypeSeed";

export const complantListColumn = (
  handleClickOpen,
  handleMapClickOpen,
  handleReassign,
  handleResolved
) => [
  {
    Header: "Date",
    accessor: "reportDate",
    sticky: "left",
    id: 1,
    width: 200,
    Cell: (props) => {
      let date = new Date(props.value);
      const formattedDate = date.toISOString().slice(0, 10);

      return <div>{formattedDate || "--"}</div>;
    },
  },
  {
    Header: "Time",
    accessor: "reportDate",
    id: 2,
    width: 200,
    Cell: (props) => {
      let date = new Date(props.value);
      let formattedTime;
      if (date[date.length - 1] === "Z") {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        formattedTime = `${hours12}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
      } else {
        let istTime = new Date(new Date(props.value).getTime());
        istTime.setHours(istTime.getHours() + 5);
        istTime.setMinutes(istTime.getMinutes() + 30);
        formattedTime = ` ${new Date(istTime).getHours()}:${new Date(
          istTime
        ).getMinutes()}:${new Date(istTime).getSeconds()}`;
      }
      return <div>{formattedTime || "--"}</div>;
    },
  },
  {
    Header: "Police Station",
    accessor: "policeStation",
    id: 3,
    width: 200,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            height: "40px",
          }}
        >
          <span>{props.value}</span>
        </div>
      );
    },
  },
  {
    Header: "Report Type",
    accessor: "reportType",
    id: 4,
    width: 200,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            height: "40px",
          }}
        >
          <span>{props.value}</span>
        </div>
      );
    },
  },
  {
    Header: "Description",
    accessor: "description",
    id: 5,
    width: 200,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            height: "40px",
          }}
        >
          <span>{props.value}</span>
        </div>
      );
    },
  },
  {
    Header: "Assigned To Police",
    accessor: "policeman",
    id: 6,
    width: 200,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "start",
            height: "40px",
          }}
        >
          {props.row.original.policemanId === null ||
          props.row.original.policemanId === "" ? (
            <>
              <button
                onClick={() =>
                  handleClickOpen(
                    props.row.original.id,
                    props.row.original.policeStationId
                  )
                }
              >
                Assign To
              </button>
              <IconButton
                onClick={() =>
                  handleMapClickOpen(
                    props.row.original.currentLatitude,
                    props.row.original.currentLongitude
                  )
                }
              >
                <MyLocationIcon />
              </IconButton>
            </>
          ) : (
            <span>
              {props.row.original.policeman?.name ||
                props.row.original.policeman}
            </span>
          )}
        </div>
      );
    },
  },
  {
    Header: "Reassigned Police Station",
    accessor: "",
    id: 7,
    width: 100,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "start",
            height: "40px",
          }}
        >
          {props.row.original.policemanId === null ||
          props.row.original.policemanId === "" ? (
            <button onClick={() => handleReassign(props.row.original.id)}>
              Reassign
            </button>
          ) : (
            <> Police already assigned</>
          )}
        </div>
      );
    },
  },
  {
    Header: "Resolve Report",
    accessor: "",
    id: 8,
    width: 100,
    Cell: (props) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "start",
            height: "40px",
          }}
        >
          {props.row.original.isResolved === 0 ? (
            <button onClick={() => handleResolved(props.row.original.id)}>
              Resolve
            </button>
          ) : (
            <> Resolved</>
          )}
        </div>
      );
    },
  },
];

export const psmInit = {
  latitude: "",
  longitude: "",
  name: "",
  password: "",
  mobileNo: "",
};

export const complaintInitVal = { policemanId: "", policeStationId: "" };

export const UPDATE = "UPDATE";
export const SUBMIT = "SUBMIT";
export const CANCEL = "CANCEL";
export const CLEARFILTER = "CLEARFILTER";
export const APPLY = "APPLY";

export const complaintFilterFields = [
  {
    id: 1,
    label: "Date",
    queryName: "reportDateDrop",
    fieldName: "reportDate",
    querySeeds: filterCamaSepratorSeeds,
  },
  {
    id: 2,
    label: "Police Station",
    queryName: "policeStationDrop",
    fieldName: "policeStation",
  },
  {
    id: 3,
    label: "Report Type",
    queryName: "reportTypeDrop",
    fieldName: "reportType",
  },
  {
    id: 4,
    label: "Description",
    queryName: "descriptionDrop",
    fieldName: "description",
  },
];

export const complaintFieldsInitial = {
  reportDate: "",
  reportDateDrop: "",
  policeStation: "",
  policeStationDrop: "",
  reportType: "",
  reportTypeDrop: "",
  description: "",
  descriptionDrop: "",
};
