import React, { useEffect, useMemo, useState } from "react";
import {
  getByIdApiServices,
  postApiServices,
  updateApiServices,
  putIdApiServices,
} from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import Cookies from "universal-cookie";
import {
  CANCEL,
  complaintFieldsInitial,
  complaintFilterFields,
  complaintInitVal,
  complantListColumn,
  SUBMIT,
} from "../../constants/complaintList";
import { CustomReactTable } from "../../shared/CustomReactTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import io from "socket.io-client";
import { CustomSelectField } from "../../shared/CustomSelectedField";
import { useFormik } from "formik";
import {
  CancelButton,
  StyledButtonContainer,
  SubmitButton,
} from "../policeStationMaster/Psm";
import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import "leaflet/dist/leaflet.css";
import { Marker, Popup, useMapEvents } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { ListContainer } from "../policeStationMaster/List";
import { ListTopbar } from "../../shared/ListTopBar";
import { Alert, AlertTitle, Collapse, Grid, IconButton } from "@mui/material";
import { errorMsg, successMsg } from "../../shared/SnackbarMsgTrigger";
import { useDispatch } from "react-redux";
import addNotification from "react-push-notification";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePath";
import {
  createQueryParams,
  parseQueryParams,
} from "../../../utils/queryParams";
import { sortedValues } from "../../../utils/commonUtils";
import CloseIcon from "@mui/icons-material/Close";

const socket = io.connect("https://student-safety.qa.casaretail.ai");

const ComplaintList = () => {
  const [complaints, setComplaints] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openReassign, setOpenReassign] = useState(false);
  const [police, setPolice] = useState([]);
  const [complaintId, setComplaintId] = useState("");
  const [center, setCenter] = useState([]);
  const navigate = useNavigate();
  const [station, setStation] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const searchdata = searchParam?.get("search");
  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 10;
  const currentPage = parseInt(pageParams?.currentPage) || 1;
  const [count, setCount] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const cookies = new Cookies();
  const stationName = cookies.get("stationName");
  // const url = require("../../../assets/alarmAudio.mp3");
  const url = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3";
  // const url =
  //   "https://schoolerpfiles.s3.ap-south-1.amazonaws.com/%7Bcamle(addressProof%7D)s/b8cf2b35-253f-47b6-af73-702b63a572b8-addressProof.png";
  const [audio] = useState(new Audio(url));
  const playPause = (bool) => {
    bool ? audio.play() : audio.pause();
  };

  const handleClickOpen = (reportId, stationId) => {
    setComplaintId(reportId);
    getByIdApiServices(apiRoutes.GET_POLICE_BY_STATION, stationId)
      .then(({ data }) => {
        setPolice(data);
      })
      .catch((res) => {
        console.log(res);
        dispatch(errorMsg(res?.response?.data?.error));
      });
    setOpen(true);
  };

  const handleMapClickOpen = () => {
    setCenter([13.085832098617304, 80.26782989501953]);
    setOpenMap(true);
  };

  const handleReassign = (id) => {
    setComplaintId(id);
    setOpenReassign(true);
  };

  const handleResolved = (id) => {
    putIdApiServices(apiRoutes.RESOLVE_ISSUE, id)
      .then(() => {
        getListData();
      })
      .catch((res) => {
        console.log(res);
        dispatch(errorMsg(res?.response?.data?.error));
      });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMap(false);
    setOpenReassign(false);
    setValues(complaintInitVal);
  };

  const onSubmitAssignForm = (value, { resetForm }) => {
    if (open) {
      let workData = {
        policemanId: value.policemanId,
      };
      updateApiServices(apiRoutes.ASSIGN_POLICEMAN, complaintId, workData)
        .then((res) => {
          dispatch(successMsg(res?.data?.message));
        })
        .catch((res) => {
          console.log(res);
          dispatch(errorMsg(res?.response?.data?.error));
        });
    } else if (openReassign) {
      let workData = {
        policeStationId: value.policeStationId,
      };
      updateApiServices(
        apiRoutes.REASSIGN_POLICE_STATION,
        complaintId,
        workData
      )
        .then((res) => {
          dispatch(successMsg(res?.data?.message));
          getListData();
        })
        .catch((res) => {
          console.log(res);
          dispatch(errorMsg(res?.response?.data?.error));
        });
    }
    resetForm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: complaintInitVal,
    onSubmit: onSubmitAssignForm,
    // validationSchema: Yup.object({
    //   policemanId: open
    //     ? Yup.string().required("Pleace select a policeman")
    //     : Yup.string(),
    //   policeStationId: openReassign
    //     ? Yup.string().required("Pleace select a police station")
    //     : Yup.string(),
    // }),
  });
  const { handleChange, values, handleSubmit, setValues } = formik;

  function LocationMarker() {
    const map = useMapEvents({
      click() {
        map.locate();
      },
    });

    return center === null ? null : (
      <Marker
        position={center}
        icon={
          new Icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
          })
        }
      >
        <Popup>Alert here</Popup>
      </Marker>
    );
  }

  const onChangePageSize = (size) => {
    const newParams = createQueryParams({
      pageSize: size,
      currentPage: size === pageSize ? currentPage : 1,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${ROUTE_PATHS.COMPLAINT_LIST}?${newParams}`);
  };

  const onPageNumberChange = (page) => {
    const newParams = createQueryParams({
      pageSize,
      currentPage: page,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${ROUTE_PATHS.COMPLAINT_LIST}?${newParams}`);
  };

  let filterdata = useMemo(
    () =>
      searchParam?.get("filter")
        ? JSON.parse(searchParam?.get("filter"))
        : stationName !== "" && stationName !== undefined
        ? [
            {
              type: "eq",
              field: "policeStation",
              value: stationName,
            },
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParam]
  );
  const sortData = useMemo(
    () =>
      searchParam?.get("sort") ? JSON.parse(searchParam?.get("sort")) : [],
    [searchParam]
  );

  const getListData = () => {
    const offset = pageSize * (currentPage - 1);
    let listParams = {
      filters: filterdata?.length !== 0 ? filterdata : [],
      search: {
        fields: ["reportDate", "policeStation", "reportType"],
        value: searchdata ? searchdata : "",
      },
      sorting: sortedValues(complantListColumn(), sortData) || [],
      pagination: {
        limit: pageSize,
        offset: offset,
      },
    };
    postApiServices(apiRoutes.COMPLAINT_LIST, listParams)
      .then((res) => {
        setComplaints(res.data.rows);
        setCount(res?.data?.count);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchdata, filterdata, sortData]);

  useEffect(() => {
    const handleNewReport = (data) => {
      setComplaints([{ ...data.report }, ...complaints]);
      playPause(true);
      setAlertOpen(true);
      addNotification({
        title: data.report.reportType,
        duration: 10000,
        native: true,
        onClick: () => {
          navigate(ROUTE_PATHS.COMPLAINT_LIST);
        },
      });
    };

    const assignReport = (data) => {
      let assignedData = [];
      complaints.map((i, index) => {
        if (i.id === data.report.id) {
          assignedData = complaints;
          assignedData.splice(index, 1);
        }
        return assignedData;
      });
      assignedData.push(data.report);
      setComplaints(assignedData);
    };
    socket.on("report", handleNewReport);

    socket.on("assignedReport", assignReport);
    return () => {
      // Clean up event listeners when component unmounts
      socket.off("report", handleNewReport);
      socket.off("assignedReport", assignReport);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaints]);

  useEffect(() => {
    postApiServices(apiRoutes.PSM_LIST, {}).then(({ data }) => {
      setStation(data.rows);
    });
  }, []);

  return (
    <ListContainer>
      <Collapse in={alertOpen}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                playPause(false);
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Alert</AlertTitle>
          <strong>new complaint!</strong>
        </Alert>
      </Collapse>
      {/* <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        <strong>New Alert!</strong>
      </Alert> */}
      <ListTopbar
        searchField
        disableNewForm
        download
        filter
        label={"COMPLAINT REPORTS"}
        filterFields={complaintFilterFields}
        filterFieldInitial={complaintFieldsInitial}
      />
      <CustomReactTable
        columnData={complantListColumn(
          handleClickOpen,
          handleMapClickOpen,
          handleReassign,
          handleResolved
        )}
        rawData={complaints}
        disableRowSelection={true}
        onChangePageSize={onChangePageSize}
        disablePagination={false}
        count={count}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageNumberChange={onPageNumberChange}
        columnSize={true}
      />

      {/* Assign police */}
      <Dialog open={open} onClose={handleClose}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <DialogTitle>Assign Police</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <CustomSelectField
                label={"Select Police"}
                name={"policemanId"}
                fullWidth
                inputValues={police}
                value={values.policemanId}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <StyledButtonContainer>
                <CancelButton
                  variant="outlined"
                  sx={{ marginRight: "20px" }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {CANCEL}
                </CancelButton>
                <SubmitButton
                  variant="contained"
                  onClick={() => handleSubmit(values)}
                  color="primary"
                >
                  {SUBMIT}
                </SubmitButton>
              </StyledButtonContainer>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>

      {/* View Map */}
      <Dialog open={openMap} onClose={handleClose}>
        <Grid container sx={{ width: "700px" }}>
          <Grid item xs={6} sx={{ marginLeft: "20px" }}>
            <h2>Complaint location</h2>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <DialogActions>
              <CancelButton
                variant="outlined"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  handleClose();
                }}
              >
                {"CLOSE"}
              </CancelButton>
            </DialogActions>
          </Grid>
          <Grid item xs={12} sx={{ height: "400px" }}>
            <div>
              <MapContainer center={center} zoom={16} scrollWheelZoom={false}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
              </MapContainer>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      {/* Reassign policeStation */}
      <Dialog open={openReassign} onClose={handleClose}>
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={12}>
            <DialogTitle>Reassign Police Station</DialogTitle>
          </Grid>
          <Grid item xs={12}>
            <DialogContent>
              <CustomSelectField
                label={"Select police station"}
                name={"policeStationId"}
                fullWidth
                inputValues={station}
                value={values.policeStationId}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </DialogContent>
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <StyledButtonContainer>
                <CancelButton
                  variant="outlined"
                  sx={{ marginRight: "20px" }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {CANCEL}
                </CancelButton>
                <SubmitButton
                  variant="contained"
                  onClick={() => handleSubmit(values)}
                  color="primary"
                >
                  {SUBMIT}
                </SubmitButton>
              </StyledButtonContainer>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </ListContainer>
  );
};

export default ComplaintList;
