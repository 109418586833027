import * as Yup from "yup";

export const policeManValidation = Yup.object({
  name: Yup.string().required("Name is required"),
  emailId: Yup.string()
    .required("User email is required")
    .email("Invalid email")
    .matches(/^[\w-\.]+@([\w-]+\.)+com$/, "Invalid email"),
  mobileNo: Yup.string()
    .min(10, "Must be 10 digit")
    .required("User mobileNo is required"),
  policeStationId: Yup.string().required("Please select police station"),
});
