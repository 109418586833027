import { CircularProgress } from "@mui/material";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getApiServices } from "../../api/api";
import { apiRoutes } from "../../api/apiPath";
import ProtectedRoute from "../shared/ProtectedRoute";
import { errorMsg } from "../shared/SnackbarMsgTrigger";
import { ToastMessage } from "../shared/ToastMessage";

const LoaderCircle = styled(CircularProgress)(() => ({
  position: "absolute",
  top: "50vh",
  left: "50%",
  zIndex: "7000",
}));

const Root = () => {
  const dispatch = useDispatch();
  // const cookies = new Cookies();
  // const getAuthToken = cookies.get("token") || "";
  // const auth = useMemo(
  //   () => (getAuthToken ? getAuthToken : ""),
  //   [getAuthToken]
  // );
  const globalState = useSelector((state) => state);

  // useEffect(() => {
  //   if (auth) {
  //     getApiServices(apiRoutes.GET_LOGIN_DETAILS_BY_TOKEN)
  //       .then(({ data: { data } }) => {
  //         dispatch(storeLoginDetails(data));
  //       })
  //       .catch((res) => dispatch(errorMsg(res?.response?.data?.message)));
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth]);

  return (
    <Suspense fallback={<LoaderCircle />}>
      <ToastMessage />
      {globalState.isLoading && <LoaderCircle />}
      <ProtectedRoute />
    </Suspense>
  );
};

export default Root;
