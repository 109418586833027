import { Box, Grid } from "@mui/material";
import React from "react";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/system";

let theme = createTheme();

const ProfileItems = styled(Box)((props) => ({
  fontFamily: "Rubik",
  textTransform: "capitalize",
  // textAlign: "center",
  marginRight: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const UserName = styled("div")(() => ({
  color: `#000`,
  font: "normal normal bold 19px/25px Roboto",
  fontWeight: "600",
}));


const LogoContainer = (props) => {
  const { userName } = props;
  return (
    <Grid justify="space-evenly" alignItems="center" container>
      <Grid item xs={12}>
        <ProfileItems isMd={userName}>
          <UserName> STUDENT SAFETY </UserName>
          {/* {!role ? <Role>MANAGEMENT</Role> : ""} */}
        </ProfileItems>
      </Grid>
    </Grid>
  );
};

export { LogoContainer };
