import { Avatar, Box, Grid } from "@mui/material";
import React from "react";
import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
// import { useSelector } from "react-redux";

let theme = createTheme();

const ProfileItems = styled(Box)(() => ({
  fontFamily: "Rubik",
  textTransform: "capitalize",
  textAlign: "right",
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  // display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  // justifyContent: "center",
  // height: "100%",
}));

const UserName = styled("div")(() => ({
  color: "black",
  // fontWeight: theme.typography.fontWeightMedium,
  font: "normal normal normal 16px/19px Rubik",
}));

const Role = styled("div")(() => ({
  fontSize: 13,
  fontWeight: "normal",
  color: "black",
}));

// const ImageContainer = styled(Grid)(() => ({
//   margin: "0px 24px 0px 16px",
// }));

const Profile = () => {
  //   const {
  //     role,
  //     loginDetails,
  //     studentDetails,
  //     loginDetails: { employeeDetails },
  //     loginDetails: { alumniDetails },
  //   } = useSelector((state) => state);

  const BasicUserImg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

  return (
    <Grid justify="space-evenly" alignItems="center" container height="100%">
      <Grid item xs={9}>
        {/* <ProfileItems>
          <UserName>{"User"}</UserName>
          <Role>{"Role"}</Role>
        </ProfileItems> */}
      </Grid>
      <Grid item xs={3}>
        <Avatar
          alt="Photo by Jeremy Hynes"
          src={
            // studentDetails?.profileUrl ||
            // employeeDetails?.pictureUrl ||
            // loginDetails?.profileUrl ||
            // alumniDetails?.photoUrl ||
            BasicUserImg
          }
        />
      </Grid>
    </Grid>
  );
};

export { Profile };
