import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { parseQueryParams } from "../../utils/queryParams";
import { CustomSelectField } from "./CustomSelectedField";
import { filterStringSeeds } from "./FilterTypeSeed";
import { CustomTextField } from "./CustomTextField";
import { CancelButton, SubmitButton } from "../pages/policeStationMaster/Psm";
import { APPLY, CANCEL, CLEARFILTER } from "../constants/complaintList";

const SuperAdminTopbarIcons = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  padding: "7px  7px",
  fontSize: 14,
  borderRadius: 4,
  color: "white",
}));

const Title = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "14px 20px",
  color: "white",
  backgroundColor: "blue",
}));

const ContainerStyle = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "900px !important",
  backgroundColor: "white",
  boxShadow: 24,
});

export const ButtonModal = styled(Box)({
  margin: 0,
  marginTop: 20,
  padding: "16px",
  boxShadow: "-2px 0px 6px #00000029",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

export const FilterModal = ({
  listPath,
  getFilterValue,
  filterFields,
  filterFieldInitial,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const pageParams = parseQueryParams(location?.search);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    let getFilterValues = filterFields.map((item) => ({
      type: data[item.queryName],
      field: item.fieldName,
      value: data[item.fieldName],
    }));

    let filterArray = getFilterValues.filter((item) => item.type && item.value);

    // employeeDetails?.campusId &&
    //   filterArray.push({
    //     type: "eq",
    //     field: "campusId",
    //     value: employeeDetails?.campusId,
    //   });

    filterArray.length !== 0 &&
      navigate({
        pathName: listPath,
        search: `?${createSearchParams({
          ...pageParams,
          filter: JSON.stringify(filterArray),
        })}`,
      });

    setOpen(false);
    // resetForm();
  };

  //to handle formik data
  const formik = useFormik({
    initialValues: filterFieldInitial,
    onSubmit,
  });

  const handleClearFilter = () => {
    const data = {
      ...pageParams,
    };
    delete data?.filter;

    navigate({
      pathName: `${listPath}`,
      search: `?${createSearchParams({
        ...data,
      })}`,
    });

    // setOpen(false);
    resetForm();
  };

  const { values, handleChange, resetForm, handleBlur } = formik;

  return (
    <div>
      <SuperAdminTopbarIcons variant="contained" onClick={handleOpen}>
        <FilterListIcon />
      </SuperAdminTopbarIcons>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContainerStyle>
          <Title>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Filter
            </Typography>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </Title>

          <form onSubmit={formik.handleSubmit}>
            {filterFields?.map((item, index) => {
              return (
                <Grid
                  container
                  columnSpacing={4}
                  rowSpacing={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: 0,
                  }}
                  key={index}
                >
                  <Grid item xs={3}>
                    <Typography sx={{}}>{item.label}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelectField
                      label={"Query"}
                      inputValues={
                        item.querySeeds
                          ? item.querySeeds
                          : filterStringSeeds || []
                      }
                      name={item?.queryName}
                      onChange={handleChange}
                      value={values?.[item?.queryName] || ""}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label={"Value"}
                      name={item.fieldName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[item.fieldName] || ""}
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              );
            })}

            <ButtonModal>
              <CancelButton
                id="modal-modal-description"
                sx={{ px: 3 }}
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                {CANCEL}
              </CancelButton>
              <CancelButton
                id="modal-modal-description"
                sx={{ px: 3 }}
                onClick={handleClearFilter}
                style={{ cursor: "pointer" }}
              >
                {CLEARFILTER}
              </CancelButton>
              <SubmitButton
                variant="contained"
                label="Apply"
                type="submit"
                sx={{ px: 3 }}
              >
                {APPLY}
              </SubmitButton>
            </ButtonModal>
          </form>
        </ContainerStyle>
      </Modal>
    </div>
  );
};
