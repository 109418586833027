import IconButton from "@mui/material/IconButton";
import { EditPopover } from "../shared/EditPopover";
import { ROUTE_PATHS } from "../../routes/routePath";

export const psListColumn = () => [
  {
    Header: "Station Name",
    accessor: "name",
    sticky: "left",
    id: 1,
    width: 300,
    Cell: (props) => {
      return (
        <div className="tabellCell">
          <span>{props?.value}</span>
          <IconButton>
            <EditPopover
              values={props}
              rowId={props.row.original.id}
              toShow={{ edit: true, viewDetails: true }}
              paths={{
                edit: `${ROUTE_PATHS.PSM_FORM}`,
                viewDetails: ROUTE_PATHS.PSM_FORM,
              }}
            />
          </IconButton>
        </div>
      );
    },
  },
  {
    Header: "Latitude",
    accessor: "latitude",
    id: 2,
    width: 250,
  },
  {
    Header: "Longitude",
    accessor: "longitude",
    id: 3,
    width: 250,
  },
];

export const stringValues = {
  new: "NEW POLICE STATION",
  edit: "EDIT POLICE STATION",
  list: "POLICE STATION MASTER",
  view: "VIEW POLICE STATION DETAILS",
};

export const psmFilterFields = [
  {
    id: 1,
    label: "Police station name",
    queryName: "nameDrop",
    fieldName: "name",
  },
];

export const psmFieldsInitial = {
  name: "",
  nameDrop: "",
};
