// import { ROUTE_PATHS } from "../../routes/routePath";

import { ROUTE_PATHS } from "../../routes/routePath";

export const STATION_RIGHT_SIDE_NAVS = [
  // {
  //   id: 1,
  //   label: "UTILITIES",
  //   name: "utilities",
  //   routePath: "",
  //   isDisable: false,
  //   isVisible: true,
  //   children: [
      // {
      //   id: 1.1,
      //   label: "My Profile",
      //   name: "profile",
      //   //   routePath: ROUTE_PATHS?.MY_PROFILE,
      //   isDisable: false,
      //   isVisible: true,
      // },
      // {
      //   id: 5,
      //   label: "Switch Role",
      //   name: "switchRole",
      //   routePath: ROUTE_PATHS?.ROLE_SELECTION,
      //   isDisable: loginDetails?.roles?.length <= 1 ? true : false,
      //   isVisible: true,
      // },
  //   ],
  // },
  {
    id: 2,
    label: "PARAMETERS",
    name: "parameters",
    routePath: "",
    isDisable: false,
    isVisible: true,
    children: [
      // {
      //   id: 2.2,
      //   label: "Station Paramters",
      //   name: "stationParameters",
      //   //   routePath: ROUTE_PATHS?.CAMPUS_LEVEL_PARAMETERS,
      //   isDisable: false,
      //   isVisible: true,
      // },
      {
        id: 2.3,
        label: "Logout",
        name: "logout",
        routePath: ROUTE_PATHS.LOGIN_SCREEN,
        isDisable: false,
        isVisible: true,
        logout: true,
      },
    ],
  },
];
