export const initialStateReducer = {
  toastMessageState: {
    severity: "",
    message: "",
    messageStatus: false,
  },
};

export const globalStateReducer = (state = initialStateReducer, action) => {
  const { type, payload } = action;
  const copyState = JSON.parse(JSON.stringify(state));
  switch (type) {
    case "TOASTMESSAGEACTION": {
      return { ...copyState, toastMessageState: payload };
    }
    case "LOADER": {
      return { ...copyState, isLoading: payload };
    }
    default:
      return state;
  }
};
