import {
    ClickAwayListener,
    Fade,
    List,
    ListItem,
    ListItemText,
    Paper,
    Popper,
  } from "@mui/material";
  import React from "react";
  // import { useNavigate } from "react-router-dom";
  import { styled } from "@mui/system";
  import { useSelector } from "react-redux";
  
  // import { createTheme } from "@mui/material/styles";
  // let theme = createTheme();
  
  const StyledPaper = styled(Paper)(() => ({
    marginLeft: 20,
    boxShadow: "0px 8px 16px #00000033 !important",
    width: "100%",
  }));
  
  const StyledPoper = styled(Popper)(() => ({
    zIndex: 2000,
    minWidth: "15%",
  }));
  
  const SidebarSubMenu = ({
    onSelectSubMenu,
    open,
    onClickAway,
    anchorEl,
    subMenus,
    selectedSubMenu,
    placement,
  }) => {
    // let navigate = useNavigate();
  
    const { role } = useSelector((state) => state);
  
    const renderForms = (item, index) => {
      return (
        <ListItem
          key={index}
          onClick={(e) => onSelectSubMenu(item, e)}
          selected={selectedSubMenu === item.id}
          button
        >
          <ListItemText
            disableTypography={true}
            color="secondary"
            sx={{ fontFamily: "Rubik, sans-serif" }}
          >
            {item.name}
          </ListItemText>
        </ListItem>
      );
    };
  
    return (
      <StyledPoper
        open={open}
        anchorEl={anchorEl}
        placement={placement || "right-start"}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <StyledPaper>
                <List>
                  {subMenus.map((item, index) => {
                    if (item?.toShow) {
                      return role?.id === item?.toShow
                        ? renderForms(item, index)
                        : "";
                    }
                    if (item?.toRemove) {
                      return role?.id === item?.toRemove
                        ? ""
                        : renderForms(item, index);
                    }
                    return renderForms(item, index);
                  })}
                </List>
              </StyledPaper>
            </Fade>
          </ClickAwayListener>
        )}
      </StyledPoper>
    );
  };
  
  export { SidebarSubMenu };