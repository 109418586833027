export const apiRoutes = {
  // complaint listing
  COMPLAINT_LIST: "reports",
  ASSIGN_POLICEMAN: "assignReportToPoliceman",
  REASSIGN_POLICE_STATION: "assignReportToOtherPoliceStation",
  GET_POLICE_BY_STATION: "policemanListByStationId",
  RESOLVE_ISSUE:"resolvedReport",

  // Psm
  CREATE_PSM: "policeStation",
  PSM_LIST: "policeStations",

  // Policeman
  POLICE_LIST: "policemanList",
  POLICEMAN: "policeman",

  // Login
  LOGIN: "login",
};
