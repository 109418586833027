import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InputBase from "@mui/material/InputBase";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { parseQueryParams } from "../../utils/queryParams";
import { useEffect, useMemo, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";

const Search = styled("div")(() => ({
    position: "relative",
    borderRadius: "10px",
    backgroundColor: "white",
    marginRight: "20px",
    marginLeft: 0,
    width: "100%",
  }));
  
  const SearchIconWrapper = styled("div")(() => ({
    padding: "0, 10px",
    marginLeft: "10px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  
  const StyledCancelIcon = styled(CancelIcon)(() => ({
    height: "20px",
    width: "20px",
  }));
  
  const StyledInputBase = styled(InputBase)(() => ({
    color: "inherit",
    boxSizing: "border-box",
    paddingRight: "12px",
    "& .MuiInputBase-input": {
      padding: "10px 10px 10px 50px",
    },
  }));
  
  export function CustomSearchField() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathName = location.pathname;
    const pageParams = parseQueryParams(location?.search);
    const search = location.search;
    const searchParam = useMemo(() => new URLSearchParams(search), [search]);
    const searchdata = searchParam?.get("search");
  
    const [value, setValue] = useState("");
  
    const clearSearch = () => {
      const data = {
        ...pageParams,
      };
      delete data?.search;
  
      setValue("");
      navigate({
        pathName: `${pathName}`,
        search: `?${createSearchParams({
          ...data,
        })}`,
      });
    };
  
    const onSearchChange = (e) => {
      if (e.charCode === 13) {
        navigate({
          pathName: `${pathName}`,
          search: `?${createSearchParams({
            ...pageParams,
            search: e.target.value,
          })}`,
        });
      }
    };
  
    useEffect(() => setValue(""), [pathName]);
    useEffect(() => {
      if (searchdata) {
        setValue(searchdata);
      }
    }, [searchdata]);
  
    return (
      <Search style={{ backgroundColor: "#F4F4F4", width: "250px" }}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          name="searchValue"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => onSearchChange(e)}
          autoComplete="off"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={clearSearch}
              >
                {value && <StyledCancelIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Search>
    );
  }