import { appApi } from "./config";

// post call
export const postApiServices = (path, value) => {
  return appApi.post(`${path}`, value);
};

// common get call
export const getApiServices = (path) => {
  return appApi.get(`${path}`);
};

// update call
export const updateApiServices = (path, id, value) => {
  return appApi.put(`${path}/${id}`, value);
};

//  get By id
export const getByIdApiServices = (path, id) => {
  return appApi.get(`${path}/${id}`);
};

// put By id
export const putIdApiServices = (path, id) => {
  return appApi.put(`${path}/${id}`);
};
