import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CANCEL, psmInit, SUBMIT, UPDATE } from "../../constants/complaintList";
import { CustomPasswordField } from "../../shared/CustomePasswordField";
import { CustomTextField } from "../../shared/CustomTextField";
import { FormTopbar } from "../../shared/FormTopbar";
import { HelpComp } from "../../shared/HelpComp";
// import { MapContainer } from "react-leaflet/MapContainer";
// import { TileLayer } from "react-leaflet/TileLayer";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "leaflet/dist/leaflet.css";
import "./Psm.css";
// import { Marker, Popup, useMap, useMapEvents } from "react-leaflet";
// import markerIconPng from "leaflet/dist/images/marker-icon.png";
// import { Icon } from "leaflet";
import {
  getByIdApiServices,
  postApiServices,
  updateApiServices,
} from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import { stringValues } from "../../constants/Psm";
import { psmValidation } from "../../validation/Psm";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { useDispatch } from "react-redux";
import { errorMsg, successMsg } from "../../shared/SnackbarMsgTrigger";

export const StyledFormContainer = styled(Box)`
  width: ${(p) => (p.formWidth ? p.formWidth : "60%")};
  margin: 30px auto;
  padding: 40px;
  border-radius: 8px;
  boxsizing: border-box;
  border: 1px solid #000;
  background-color: #fff;
`;

export const SubmitButton = styled(Button)(() => {
  return {
    color: "white",
    margin: "0 8px",
    height: "36px",
  };
});

export const CancelButton = styled(Button)(() => ({
  color: "#000",
  margin: "0 8px",
  marginRight: "20px !important",
  fontWeight: "bold",
  border: `1px solid #000`,
  height: "36px",
}));

export const StyledButtonContainer = styled(Box)((props) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingTop: 30,
  ...props?.style,
}));

const PoliceStationMaster = () => {
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  const isViewMode = searchParam?.get("isViewMode");
  // const [position, setPosition] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // let center = [13.079, 80.2659];
  // const zoom = 12;

  const onSubmitPsmForm = (value) => {
    let workData = {
      ...value,
      ...(typeof value.latitude === "number"
        ? { latitude: JSON.stringify(value.latitude) }
        : { latitude: value.latitude }),
      ...(typeof value.longitude === "number"
        ? { longitude: JSON.stringify(value.longitude) }
        : { longitude: value.longitude }),
    };
    editId
      ? updateApiServices(apiRoutes.CREATE_PSM, editId, workData)
          .then((res) => {
            navigate(ROUTE_PATHS.PSM_LIST);
            dispatch(successMsg(res?.data?.message));
          })
          .catch((res) => {
            console.log(res);
            dispatch(errorMsg(res?.response?.data?.error));
          })
      : postApiServices(apiRoutes.CREATE_PSM, workData)
          .then((res) => {
            navigate(ROUTE_PATHS.PSM_LIST);
            dispatch(successMsg(res?.data?.message));
          })
          .catch((res) => {
            console.log(res);
            dispatch(errorMsg(res?.response?.data?.error));
          });
  };

  // function LocationMarker() {
  //   const map = useMapEvents({
  //     click() {
  //       map.locate();
  //     },
  //   });
  //   !isViewMode &&
  //     map.on("click", function (e) {
  //       setPosition([e.latlng.lat, e.latlng.lng]);
  //       setValues({
  //         ...values,
  //         latitude: e.latlng.lat,
  //         longitude: e.latlng.lng,
  //       });
  //     });

  //   return position === null ? null : (
  //     <Marker
  //       position={editId ? [values.latitude, values.longitude] : position}
  //       icon={
  //         new Icon({
  //           iconUrl: markerIconPng,
  //           iconSize: [25, 41],
  //           iconAnchor: [12, 41],
  //         })
  //       }
  //     >
  //       <Popup>You are here</Popup>
  //     </Marker>
  //   );
  // }

  const formik = useFormik({
    initialValues: psmInit,
    onSubmit: onSubmitPsmForm,
    validationSchema: psmValidation,
  });
  const { handleChange, values, handleSubmit, setValues } = formik;

  useEffect(() => {
    if (editId) {
      getByIdApiServices(apiRoutes.CREATE_PSM, editId)
        .then(({ data }) => {
          setValues({
            ...data,
          });
          // setPosition([values.latitude, values.longitude]);
        })
        .catch((res) => {
          console.log(res);
          dispatch(errorMsg(res?.response?.data?.error));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  return (
    <Box>
      <FormTopbar
        label={
          isViewMode
            ? stringValues.view
            : editId
            ? stringValues.edit
            : stringValues.new
        }
        listPath={ROUTE_PATHS.PSM_LIST}
      />
      {/* <BackgroundImg /> */}
      <Box sx={{ overFlow: "auto", height: "calc(100vh - 174px)" }}>
        <StyledFormContainer>
          <HelpComp />
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={6}>
              <CustomTextField
                label={"Name"}
                name={"name"}
                type={"text"}
                isViewMode={isViewMode}
                fullWidth
                value={values.name}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label={"Mobile No"}
                name={"mobileNo"}
                type={"mobile"}
                isViewMode={isViewMode}
                maxLength={10}
                fullWidth
                value={values.mobileNo}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomPasswordField
                fullWidth
                name={"password"}
                label={"Password"}
                showEyeIcon
                autoComplete={"off"}
                isViewMode={isViewMode}
                value={values.password}
                onChange={handleChange}
                error={formik.touched["password"] && formik.errors["password"]}
                helperText={
                  formik.touched["password"] && formik.errors["password"]
                    ? formik.errors["password"]
                    : ""
                }
                sx={{
                  "& div": {
                    backgroundColor: "#fff",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label={"Latitude"}
                name={"latitude"}
                type={"alphaNumeric"}
                isViewMode={isViewMode}
                fullWidth
                value={values.latitude}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                label={"Longitude"}
                name={"longitude"}
                type={"alphaNumeric"}
                isViewMode={isViewMode}
                fullWidth
                value={values.longitude}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </Grid>
            {/* <Grid item xs={12} sx={{ height: "400px" }}>
              <div>
                <MapContainer
                  center={center}
                  zoom={zoom}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <LocationMarker />
                </MapContainer>
              </div>
            </Grid> */}
            <Grid item xs={12}>
              <StyledButtonContainer>
                {!isViewMode && (
                  <>
                    <CancelButton
                      variant="outlined"
                      sx={{ marginRight: "20px" }}
                      onClick={() => {
                        navigate(ROUTE_PATHS.PSM_LIST);
                      }}
                    >
                      {CANCEL}
                    </CancelButton>
                    <SubmitButton
                      variant="contained"
                      onClick={() => handleSubmit(values)}
                      color="primary"
                    >
                      {editId ? UPDATE : SUBMIT}
                    </SubmitButton>
                  </>
                )}
              </StyledButtonContainer>
            </Grid>
          </Grid>
        </StyledFormContainer>
      </Box>
    </Box>
  );
};

export default PoliceStationMaster;
