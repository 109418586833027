import React from "react";
import { styled } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { CustomSearchField } from "./CustomReactTable";
import AddIcon from "@mui/icons-material/Add";
import { FilterModal } from "./FilterModal";
import { CustomSearchField } from "./CustomeSearchField";

const Container = styled(Box)({
  height: 80,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});
const ContianerAlign = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const Title = styled(Typography)(() => ({
  color: `#000`,
}));
const IconsContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
const NewButton = styled(Button)({
  padding: "7px",
  minWidth: 0,
  marginLeft: "8px",
});

export function ListTopbar(props) {
  const navigate = useNavigate();

  const {
    label,
    disableSearchField,
    disableFilter,
    disableNewForm,
    newFormPath,
    listPath,
    getFilterValue,
    filterFields,
    filterFieldInitial,
  } = props;

  return (
    <Container>
      <ContianerAlign>
        <Title variant="h6">{label}</Title>
        <IconsContainer>
          {!disableSearchField && <CustomSearchField />}
          {!disableFilter && (
            <Button>
              <FilterModal
                listPath={listPath}
                getFilterValue={getFilterValue}
                filterFields={filterFields}
                filterFieldInitial={filterFieldInitial}
              />
            </Button>
          )}
          {!disableNewForm && (
            <NewButton
              onClick={() => navigate(newFormPath)}
              variant="contained"
            >
              <AddIcon />
            </NewButton>
          )}
        </IconsContainer>
      </ContianerAlign>
    </Container>
  );
}
