export const sortedValues = (column, value) => {
    const getObj = column?.find((item) => item.id === value?.[0]?.id);
    let output = [];
  
    if (value?.length > 0) {
      output.push({
        column: getObj?.accessor || "",
        order: value?.[0]?.desc ? "desc" : "asc",
      });
    }
  
    return output;
  };