import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { sortedValues } from "../../../utils/commonUtils";
import {
  createQueryParams,
  parseQueryParams,
} from "../../../utils/queryParams";
import {
  psListColumn,
  psmFieldsInitial,
  psmFilterFields,
  stringValues,
} from "../../constants/Psm";
import { CustomReactTable } from "../../shared/CustomReactTable";
import { ListTopbar } from "../../shared/ListTopBar";
import { errorMsg } from "../../shared/SnackbarMsgTrigger";

export const ListContainer = styled(Box)({
  boxSizing: "border-box",
  width: "96%",
  paddingLeft: 30,
});

const PsmList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = location.search;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const searchdata = searchParam?.get("search");
  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 10;
  const currentPage = parseInt(pageParams?.currentPage) || 1;
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  //   const {
  //     loginDetails: { employeeDetails },
  //     role,
  //   } = useSelector((state) => state);

  const onChangePageSize = (size) => {
    const newParams = createQueryParams({
      pageSize: size,
      currentPage: size === pageSize ? currentPage : 1,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${ROUTE_PATHS.PSM_LIST}?${newParams}`);
  };
  let filterdata = useMemo(
    () =>
      searchParam?.get("filter") ? JSON.parse(searchParam?.get("filter")) : [],
    [searchParam]
  );
  const sortData = useMemo(
    () =>
      searchParam?.get("sort") ? JSON.parse(searchParam?.get("sort")) : [],
    [searchParam]
  );
  const onPageNumberChange = (page) => {
    const newParams = createQueryParams({
      pageSize,
      currentPage: page,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${ROUTE_PATHS.PSM_LIST}?${newParams}`);
  };

  useEffect(() => {
    const offset = pageSize * (currentPage - 1);
    let listParams = {
      filters: filterdata?.length !== 0 ? filterdata : [],
      search: {
        fields: ["stationName", "latitude", "longitude"],
        value: searchdata ? searchdata : "",
      },
      sorting: sortedValues(psListColumn(), sortData) || [],
      pagination: {
        limit: pageSize,
        offset: offset,
      },
    };
    postApiServices(apiRoutes.PSM_LIST, listParams)
      .then(({ data }) => {
        setTableData(data.rows);
        setCount(data?.count);
      })
      .catch((res) => dispatch(errorMsg(res?.response?.data?.error)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    searchdata,
    filterdata,
    sortData,
    // role?.id,
  ]);

  return (
    <ListContainer>
      <ListTopbar
        newForm
        label={stringValues.list}
        newFormPath={`${ROUTE_PATHS.PSM_FORM}`}
        listPath={ROUTE_PATHS.PSM_LIST}
        filterFields={psmFilterFields}
        filterFieldInitial={psmFieldsInitial}
      />
      <CustomReactTable
        columnData={psListColumn()}
        rawData={tableData}
        disableRowSelection={true}
        onChangePageSize={onChangePageSize}
        // disablePagination={true}
        count={count}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageNumberChange={onPageNumberChange}
        columnSize={true}
      />
    </ListContainer>
  );
};

export default PsmList;
