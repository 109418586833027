import * as Yup from "yup";

export const psmValidation = Yup.object({
  name: Yup.string().required("Station name is required"),
  mobileNo: Yup.string()
    .min(10, "Must be 10 digit")
    .required("User mobileNo is required"),
  password: Yup.string().required("Password is required"),
  latitude:Yup.string().required("Latitude is required"),
  longitude:Yup.string().required("Longitude is required"),
});