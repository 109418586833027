import Table from "custom_react_table7";
// import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export function CustomReactTable(props) {
  const {
    columnData,
    rawData,
    style,
    columnSize,
    count,
    onPageNumberChange,
    onChangePageSize,
    disablePagination,
    disableColumnHiding,
    disableSort,
    disableRowSelection = "",
    selectedRows,
    pageSize,
    currentPage,
    // manualSort,
    // onSort,
    // isLoading,
  } = props;

  //   const globalState = useSelector((state) => state);
  //   const themeColor = useTheme();
  const location = useLocation();

  const tableSortValues = () => {};

  return (
    <>
      <div>
        <Table
          columnData={columnData}
          rawData={rawData}
          selectRows={selectedRows}
          columnSize={columnSize ? columnSize : true}
          disableColumnHiding={
            disableColumnHiding ? disableColumnHiding : false
          }
          disableRowSelection={
            disableRowSelection !== "" ? disableRowSelection : true
          }
          pageSizes={pageSize}
          currentPage={currentPage}
          pagination={true}
          disablePagination={disablePagination}
          onPageNumberChange={onPageNumberChange}
          onChangePageSize={onChangePageSize}
          pageCount={count}
          disableColumnResize={true}
          disableSort={disableSort}
          manualSort={false}
          onSort={tableSortValues}
          //   isLoading={globalState?.isLoading || false}
          style={{
            th: {
              color: "#FFFFFF",
              font: "normal normal bold 14px/19px Roboto !important",
              display: "flex !important",
              alignItems: "center",
              height: "64px !important",
              //   backgroundColor: `${themeColor?.palette.secondaryAppColor.main}`,
            },
            body: {
              position: "relative",
              zIndex: "0",
              backgroundColor: "white",
              color: "#000000DE",
              font: "normal normal normal 14px/19px Roboto !important",
            },
            tableHead: {
              //   backgroundColor: `${themeColor?.palette.secondaryAppColor.main}`,
            },
            pagination: {
              //   backgroundColor: `${themeColor?.palette.paginationColor.main} !important`,
            },
            loaderContainer: {
              display: "none !important",
            },

            ...style,
          }}
        />
      </div>
    </>
  );
}
