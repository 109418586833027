import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import { ROUTE_PATHS } from "../../../routes/routePath";
import {
  createQueryParams,
  parseQueryParams,
} from "../../../utils/queryParams";
import {
  policeListColumn,
  policeManstringValues,
  policemanFieldsInitial,
  policemarouteNamenFilterFields,
} from "../../constants/policeMan";
import { CustomReactTable } from "../../shared/CustomReactTable";
import { ListTopbar } from "../../shared/ListTopBar";
import { errorMsg } from "../../shared/SnackbarMsgTrigger";
import { ListContainer } from "../policeStationMaster/List";
import { sortedValues } from "../../../utils/commonUtils";

const PoliceManList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = location.search;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const searchdata = searchParam?.get("search");
  const pageParams = parseQueryParams(location?.search);
  const pageSize = parseInt(pageParams?.pageSize) || 10;
  const currentPage = parseInt(pageParams?.currentPage) || 1;
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  // const {
  //   loginDetails: { employeeDetails },
  //   role,
  // } = useSelector((state) => state);

  const onChangePageSize = (size) => {
    const newParams = createQueryParams({
      pageSize: size,
      currentPage: size === pageSize ? currentPage : 1,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${ROUTE_PATHS.POLICEMAN_LIST}?${newParams}`);
  };
  let filterdata = useMemo(
    () =>
      searchParam?.get("filter") ? JSON.parse(searchParam?.get("filter")) : [],
    [searchParam]
  );
  const sortData = useMemo(
    () =>
      searchParam?.get("sort") ? JSON.parse(searchParam?.get("sort")) : [],
    [searchParam]
  );
  const onPageNumberChange = (page) => {
    const newParams = createQueryParams({
      pageSize,
      currentPage: page,
      ...(searchdata && { search: searchdata }),
    });
    navigate(`${ROUTE_PATHS.POLICEMAN_LIST}?${newParams}`);
  };

  useEffect(() => {
    const offset = pageSize * (currentPage - 1);
    let listParams = {
      filters: filterdata?.length !== 0 ? filterdata : [],
      search: {
        fields: ["name", "mobileNo", "emailId"],
        value: searchdata ? searchdata : "",
      },
      sorting: sortedValues(policeListColumn(), sortData) || [],
      pagination: {
        limit: pageSize,
        offset: offset,
      },
    };
    postApiServices(apiRoutes.POLICE_LIST, listParams)
      .then(({ data }) => {
        setTableData(data.rows);
        setCount(data?.count);
      })
      .catch((res) => dispatch(errorMsg(res?.response?.data?.error)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    searchdata,
    filterdata,
    sortData,
    // role?.id,
  ]);

  return (
    <ListContainer>
      <ListTopbar
        searchField
        newForm
        download
        filter
        label={policeManstringValues.list}
        newFormPath={`${ROUTE_PATHS.POLICEMAN_FORM}`}
        listPath={ROUTE_PATHS.POLICEMAN_LIST}
        filterFields={policemarouteNamenFilterFields}
        filterFieldInitial={policemanFieldsInitial}
      />
      <CustomReactTable
        columnData={policeListColumn()}
        rawData={tableData || []}
        disableRowSelection={true}
        onChangePageSize={onChangePageSize}
        disablePagination={false}
        count={count}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageNumberChange={onPageNumberChange}
        columnSize={true}
      />
    </ListContainer>
  );
};

export default PoliceManList;
