import { createStore } from "redux";
import { globalStateReducer } from "./reducer";

const store = createStore(
  globalStateReducer,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
);

export default store;
