import ComplaintList from "../components/pages/complaintListing/List";
import loginForm from "../components/pages/login/LoginForm";
import PoliceManList from "../components/pages/policeMenMaster/List";
import PoliceMenMasterForm from "../components/pages/policeMenMaster/PoliceMenMaster";
import PsmList from "../components/pages/policeStationMaster/List";
import PoliceStationMaster from "../components/pages/policeStationMaster/Psm";
import { ROUTE_PATHS } from "./routePath";

export const adminRoutes = [
  {
    path: ROUTE_PATHS.LOGIN_SCREEN,
    Component: loginForm,
    exact: true,
  },
  {
    path: ROUTE_PATHS.PSM_LIST,
    Component: PsmList,
    exact: true,
  },
  {
    path: ROUTE_PATHS.PSM_FORM,
    Component: PoliceStationMaster,
    exact: true,
  },
  {
    path: ROUTE_PATHS.POLICEMAN_LIST,
    Component: PoliceManList,
    exact: true,
  },
  {
    path: ROUTE_PATHS.POLICEMAN_FORM,
    Component: PoliceMenMasterForm,
    exact: true,
  },
  {
    path: ROUTE_PATHS.COMPLAINT_LIST,
    Component: ComplaintList,
    exact: true,
  },
];

export const nonAdminRoutes = [
  {
    path: ROUTE_PATHS.LOGIN_SCREEN,
    Component: loginForm,
    exact: true,
  },
  {
    path: ROUTE_PATHS.COMPLAINT_LIST,
    Component: ComplaintList,
    exact: true,
  },
];
