import { AppBar, Button, Toolbar } from "@mui/material";
import React, { useRef, useState } from "react";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import { LogoContainer } from "./LogoContainer";
import { Profile } from "./Profile";
import { RightMenu } from "./RightMenu";

const StyledToolbar = styled(Toolbar)(() => ({
  display: "flex",
  width: "100%",
  height: 64,
  gridTemplateColumns: "1fr 4fr 1fr",
  justifyContent: "space-between",
  backgroundColor: "white",
}));

const NavItems1 = styled("div")(() => ({
  height: "100% ",
  width: "15%",
  display: "flex",
  minWidth: 280,
  alignItems: "center",
}));

const NavItems2 = styled("div")(() => ({
  height: "100% ",
  minWidth: 280,
  width: "15%",
  display: "flex",
  alignItems: "center",
}));

const StyledButton = styled(Button)(() => ({
  width: "100%",
  height: "100%",
  display: "inline-block",
}));

const Navbar = (props) => {
  const navigate = useNavigate();
  //   const { role } = useSelector((state) => state);

  // const [subMenuOpen, setSubMenuOpen] = useState(null);
  // const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleSelectMenu = (item) => (event) => {
  //   if (item.values) {
  //     setSubMenuOpen(item.id);
  //     setAnchorEl(event.currentTarget);
  //     return;
  //   }
  //   setSubMenuOpen(null);
  // };

  // //Event to Close sub menu
  // const handleCloseSubMenu = () => {
  //   setSubMenuOpen(null);
  //   setAnchorEl(null);
  // };

  // //To select a module in sub menu
  // const handleSelectSubMenu = (item) => (subItem, e) => {
  //   setSelectedSubMenu(subItem.id);
  //   setSubMenuOpen(null);
  //   setAnchorEl(null);
  // };

  const redirect = (routePath) => {
    routePath && navigate(routePath);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        borderBottom: `1px solid #000`,
        zIndex:"100000"
      }}
      component="header"
      position="fixed"
    >
      <StyledToolbar disableGutters>
        <NavItems2>
          <LogoContainer />
        </NavItems2>
        <NavItems1>
          <StyledButton
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            component="span"
          >
            <Profile />
          </StyledButton>
          <RightMenu
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            redirect={redirect}
            // classes={classes}
          />
        </NavItems1>
      </StyledToolbar>
    </AppBar>
  );
};

export { Navbar };
