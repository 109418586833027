import React from "react";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/system";

const StyledTextField = styled(TextField)(({ showpassword, value }) => {
  return {
    "& div > input": {
      fontFamily: !showpassword && value && "text-security-disc",
      "-webkit-text-security": !showpassword && value && "disc",
    },
  };
});

export function CustomPasswordField(props) {
  const {
    name,
    onChange,
    value,
    variant,
    onBlur,
    label,
    disabled,
    style,
    isViewMode,
    showEyeIcon,
    fullWidth,
    formik,
    error,
    helpertext,
    placeholder,
    sx,
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledTextField
      id="standard-basic"
      label={label}
      placeholder={placeholder}
      variant={variant ? variant : "outlined"}
      type={"text"}
      name={name}
      fullWidth={fullWidth || true}
      autoComplete={"off"}
      onChange={onChange}
      onBlur={onBlur}
      value={value || ""}
      style={style}
      sx={sx}
      showpassword={showPassword}
      error={error || (formik?.touched?.[name] && formik?.errors?.[name])}
      helperText={
        helpertext ||
        error ||
        (formik?.touched?.[name] && formik?.errors?.[name]
          ? formik?.errors?.[name]
          : "")
      }
      InputProps={{
        readOnly: isViewMode,
        disabled: disabled,
        endAdornment: showEyeIcon && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              color="primary"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
