import { Box, styled } from "@mui/system";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Cookies from "universal-cookie";
import {
  StyledButtonContainer,
  SubmitButton,
} from "../policeStationMaster/Psm";
import { SUBMIT } from "../../constants/complaintList";
import { Grid, Typography } from "@mui/material";
import { CustomPasswordField } from "../../shared/CustomePasswordField";
import { CustomTextField } from "../../shared/CustomTextField";
import { useFormik } from "formik";
import { postApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { errorMsg, successMsg } from "../../shared/SnackbarMsgTrigger";
import { useDispatch } from "react-redux";
import { loginValidation } from "../../validation/loginForm";

const BackgroundImg = styled(Box)`
  background-image: url("https://newstrailindia.com/uploads/article_image_uploads/photos_12624bb1de05f88_1649127902_orginal.jpg");
  height: 100vh;
  width: 100%;
  background-size: 100% 100vh;
  filter: blur(8px);
  -webkit-filter: blur(8px);
`;

const FormMainContainer = styled(Box)`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const CardContainer = styled(Card)`
  width: 400px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const StyledTypography = styled(Typography)`
  font-size: 30px;
  font-weight: bold;
`;

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const loginInit = {
    mobileNo: "",
    password: "",
  };

  const onSubmitLoginForm = (value) => {
    let workData = {
      ...value,
    };
    postApiServices(apiRoutes.LOGIN, workData)
      .then((res) => {
        navigate(ROUTE_PATHS.COMPLAINT_LIST);
        cookies.set("isAdmin", res?.data?.isSuperAdmin, { path: "/" });
        res?.data?.isPoliceStationAdmin
          ? cookies.set("stationName", res?.data?.name, { path: "/" })
          : cookies.set("stationName", "", { path: "/" });
        dispatch(successMsg("You are logged in"));
      })
      .catch((res) => {
        console.log(res);
        dispatch(errorMsg(res?.response?.data?.error));
      });
  };

  const formik = useFormik({
    initialValues: loginInit,
    onSubmit: onSubmitLoginForm,
    validationSchema: loginValidation,
  });
  const { handleChange, values, handleSubmit } = formik;

  return (
    <>
      <BackgroundImg></BackgroundImg>
      <FormMainContainer>
        <CardContainer>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <StyledTypography>LOGIN</StyledTypography>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                label={"Mobile No"}
                name={"mobileNo"}
                type={"mobile"}
                maxLength={10}
                fullWidth
                value={values.mobileNo}
                onChange={(e, val) => {
                  handleChange(e, val);
                }}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomPasswordField
                fullWidth
                name={"password"}
                label={"Password"}
                showEyeIcon
                autoComplete={"off"}
                value={values.password}
                onChange={handleChange}
                error={formik.touched["password"] && formik.errors["password"]}
                helperText={
                  formik.touched["password"] && formik.errors["password"]
                    ? formik.errors["password"]
                    : ""
                }
                sx={{
                  "& div": {
                    backgroundColor: "#fff",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButtonContainer
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <>
                  <SubmitButton
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                    color="primary"
                  >
                    {SUBMIT}
                  </SubmitButton>
                </>
              </StyledButtonContainer>
            </Grid>
          </Grid>
          <CardActions></CardActions>
        </CardContainer>
      </FormMainContainer>
    </>
  );
};

export default LoginForm;
