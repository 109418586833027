import React from "react";
import {
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/system";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { STATION_RIGHT_SIDE_NAVS } from "../constants/rightMenu";

const RightSideBarTitle = styled("div")(() => ({
  display: "flex",
  width: "100%",
  gridTemplateColumns: "1fr 4fr 1fr",
  // justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: `#fff`,
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  pointerEvents: "none",
  padding: "13px 16px",
  font: "normal normal bold 16px/19px Lato",
  textAlign: "center",
  color: `#000`,
}));

const ChildMenuItem = styled(MenuItem)(() => ({
  padding: "13px 16px",
  font: "normal normal 500 16px/19px Lato",
  width: "100%",
  color: "#333333",
}));

const HeaderRightMenu = ({ redirect, anchorEl, onClose, open }, ref) => {
  const cookies = new Cookies();
  const { role } = useSelector((state) => state);

  const handleLogout = () => {
    cookies.remove("isAdmin", { path: "/" });
  };

  const renderRightMenus = () => {
    return STATION_RIGHT_SIDE_NAVS;
  };

  const renderHeader = (parent) => {
    return parent?.children ? (
      <RightSideBarTitle>
        <StyledMenuItem aria-readonly="true">{parent.label}</StyledMenuItem>
      </RightSideBarTitle>
    ) : (
      renderForms(parent)
    );
  };

  const renderForms = (child) => {
    return !child?.isDisable ? (
      <ChildMenuItem
        onClick={(e) => {
          onClose(e);
          child?.routePath && redirect(child.routePath);
          child?.logout && handleLogout();
        }}
        key={child.name}
      >
        {child.label}
      </ChildMenuItem>
    ) : (
      ""
    );
  };

  return (
    <Popper
      style={{ zIndex: 999 }}
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center-top" : "center-bottom",
          }}
        >
          <Paper sx={{ minWidth: "240px" }}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {renderRightMenus().map((parent) => {
                  return (
                    <div>
                      {role?.id && role?.id === parent?.toRemove
                        ? ""
                        : renderHeader(parent)}

                      {parent?.children?.length &&
                        parent?.children?.map((child) => {
                          if (child?.toShow) {
                            return role?.id === child?.toShow
                              ? renderForms(child)
                              : "";
                          }
                          if (child?.toRemove) {
                            return role?.id === child?.toRemove
                              ? ""
                              : renderForms(child);
                          }
                          return renderForms(child);
                        })}
                    </div>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
const RightMenu = React.forwardRef(HeaderRightMenu);

export { RightMenu };
