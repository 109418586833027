import IconButton from "@mui/material/IconButton";
import { EditPopover } from "../shared/EditPopover";
import { ROUTE_PATHS } from "../../routes/routePath";

export const policeListColumn = () => [
  {
    Header: "Police Name",
    accessor: "name",
    sticky: "left",
    id: 1,
    width: 300,
    Cell: (props) => {
      return (
        <div className="tabellCell">
          <span>{props?.value}</span>
          <IconButton>
            <EditPopover
              values={props}
              rowId={props.row.original.id}
              toShow={{ edit: true, viewDetails: true }}
              paths={{
                edit: `${ROUTE_PATHS.POLICEMAN_FORM}`,
                viewDetails: ROUTE_PATHS.POLICEMAN_FORM,
              }}
            />
          </IconButton>
        </div>
      );
    },
  },
  {
    Header: "Mobile No",
    accessor: "mobileNo",
    id: 2,
    width: 250,
  },
  {
    Header: "Email ID",
    accessor: "emailId",
    id: 3,
    width: 250,
  },
];

export const policeManstringValues = {
  new: "NEW POLICE",
  edit: "EDIT POLICE",
  list: "POLICE MASTER",
  view: "VIEW POLICE DETAILS",
};

export const policeManInit = {
  name: "",
  mobileNo: "",
  emailId: "",
  policeStationId: "",
};

export const policemarouteNamenFilterFields = [
  {
    id: 1,
    label: "Police Name",
    queryName: "nameDrop",
    fieldName: "name",
  },
  {
    id: 2,
    label: "Mobile No",
    queryName: "mobileNoDrop",
    fieldName: "mobileNo",
  },
  {
    id: 3,
    label: "Email Id",
    queryName: "emailIdDrop",
    fieldName: "emailId",
  },
];

export const policemanFieldsInitial = {
  name: "",
  nameDrop: "",
  mobileNo: "",
  mobileNoDrop: "",
  emailId: "",
  emailIdDrop: "",
};