import React, { useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { adminSidebarMenus, nonAdminSidebarMenus } from "../constants/sidebarMenus";
import { SidebarSubMenu } from "./SidebarSubMenus";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Cookies from "universal-cookie";
import { ROUTE_PATHS } from "../../routes/routePath";

const SideContainer = styled(List)(() => ({
  height: "calc(100vh - 80px) ",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#fff",
}));

const StyledListItemText = styled(ListItemText)(({ selected }) => ({
  font: " normal normal normal 14px/17px Rubik",
  color: selected ? "white" : "#707070",
}));

const StyledListItem = styled(ListItem)(() => ({
  borderRadius: "5px !important",
  width: "90%",
}));

const Sidebar = ({ history }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(null);
  const cookies = new Cookies();
  const isAdmin = cookies.get("isAdmin");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  //Event to Select the menu
  const handleSelectMenu = (item) => (event) => {
    if (item.values) {
      setSubMenuOpen(item.id);
      setAnchorEl(event.currentTarget);
      return;
    }
    setSubMenuOpen(null);
    item.route && navigate(item.route);
    // history.push(item.route || pathname);
  };

  //Event to Close sub menu
  const handleCloseSubMenu = () => {
    setSubMenuOpen(null);
    setAnchorEl(null);
  };

  //To select a module in sub menu
  const handleSelectSubMenu = (item) => (subItem, e) => {
    navigate(subItem?.route);
    // subItem?.route && history?.push(subItem?.route);
    setSelectedSubMenu(subItem.id);
    setSubMenuOpen(null);
    setAnchorEl(null);
  };

  const renderForms = (item, selected) => {
    return (
      <StyledListItem
        key={item.id}
        button
        onClick={handleSelectMenu(item)}
        selected={selected}
        sx={{
          ...(selected
            ? {
                backgroundColor: `#cce6ff !important`,
              }
            : {}),
          marginBottom: 1.5,
        }}
      >
        <StyledListItemText disableTypography={true} selected={selected}>
          {item.name}
        </StyledListItemText>
      </StyledListItem>
    );
  };

  const renderSidebarMenus = () => {
    if(isAdmin === "true"){
      return adminSidebarMenus
    }else if(isAdmin === "false"){
      return nonAdminSidebarMenus
    }else{
      navigate(ROUTE_PATHS.LOGIN_SCREEN)
      return [];
    }
  };

  const getSelectedModule = (item) => {
    return location?.pathname?.split("/")?.[1]?.includes(item?.routeKey);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <SideContainer component="nav">
        {renderSidebarMenus().map((item) => {
          const selected = getSelectedModule(item);

          return (
            <React.Fragment key={item.id}>
              {item?.toShow
                ? item?.toShow
                  ? renderForms(item, selected)
                  : ""
                : renderForms(item, selected)}

              {!item.values ? null : (
                <SidebarSubMenu
                  onClickAway={handleCloseSubMenu}
                  onSelectSubMenu={handleSelectSubMenu(item)}
                  open={subMenuOpen === item.id}
                  anchorEl={anchorEl}
                  subMenus={item.values}
                  selectedSubMenu={selectedSubMenu}
                />
              )}
            </React.Fragment>
          );
        })}
      </SideContainer>
    </Box>
  );
};

export { Sidebar };
