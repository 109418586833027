import React from "react";
import { createTheme } from "@mui/material/styles";
import { Navbar } from "./Navbar";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import { Sidebar } from "./Sidebar";

let theme = createTheme();

const MainLayout = styled("div")(() => ({
  paddingTop: theme.spacing(8),
}));

const BodyContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
}));

const SidebarContainer = styled(Box)(() => ({
  width: "15%",
  minWidth: 280,
  height: "100%",
  borderRight: `1px solid #000`,
}));

const StyledLayout = styled(Box)(() => ({
  overflow: "auto",
  height: "100%",
  width: "100%",
}));

export function Layout() {
  return (
    <MainLayout>
      <Navbar />
      <BodyContainer>
        <SidebarContainer>
          <StyledLayout>
            <Sidebar history={{}} />
          </StyledLayout>
        </SidebarContainer>
        <StyledLayout>
          <Outlet />
        </StyledLayout>
      </BodyContainer>
    </MainLayout>
  );
}
