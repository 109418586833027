import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomSelectField } from "../../shared/CustomSelectedField";
import { CustomTextField } from "../../shared/CustomTextField";
import { FormTopbar } from "../../shared/FormTopbar";
import { HelpComp } from "../../shared/HelpComp";
import {
  CancelButton,
  StyledButtonContainer,
  StyledFormContainer,
  SubmitButton,
} from "../policeStationMaster/Psm";
import {
  policeManInit,
  policeManstringValues,
} from "../../constants/policeMan";
import { useFormik } from "formik";
import {
  getByIdApiServices,
  postApiServices,
  updateApiServices,
} from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import { CANCEL, SUBMIT, UPDATE } from "../../constants/complaintList";
import { policeManValidation } from "../../validation/policeMan";
import { ROUTE_PATHS } from "../../../routes/routePath";
import { errorMsg, successMsg } from "../../shared/SnackbarMsgTrigger";
import { useDispatch } from "react-redux";

const PoliceMenMasterForm = () => {
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search);
  const editId = searchParam?.get("editId");
  const isViewMode = searchParam?.get("isViewMode");
  const [station, setStation] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmitPmForm = (value) => {
    let workData = {
      ...value,
    };
    editId
      ? updateApiServices(apiRoutes.POLICEMAN, editId, workData)
          .then((res) => {
            navigate(ROUTE_PATHS.POLICEMAN_LIST);
            dispatch(successMsg(res?.data?.message));
          })
          .catch((res) => {
            console.log(res);
            dispatch(errorMsg(res?.response?.data?.error));
          })
      : postApiServices(apiRoutes.POLICEMAN, workData)
          .then((res) => {
            navigate(ROUTE_PATHS.POLICEMAN_LIST);
            dispatch(successMsg(res?.data?.message));
          })
          .catch((res) => {
            console.log(res);
            dispatch(errorMsg(res?.response?.data?.error));
          });
  };

  const formik = useFormik({
    initialValues: policeManInit,
    onSubmit: onSubmitPmForm,
    validationSchema: policeManValidation,
  });
  const { handleChange, values, handleSubmit, setValues } = formik;

  useEffect(() => {
    postApiServices(apiRoutes.PSM_LIST, {}).then(({ data }) => {
      setStation(data.rows);
    });
  }, []);

  useEffect(() => {
    if (editId) {
      getByIdApiServices(apiRoutes.POLICEMAN, editId)
        .then(({ data }) => {
          setValues({
            ...data,
          });
        })
        .catch((res) => {
          console.log(res);
          dispatch(errorMsg(res?.response?.data?.error));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId]);

  return (
    <Box>
      <FormTopbar
        label={
          isViewMode
            ? policeManstringValues.view
            : editId
            ? policeManstringValues.edit
            : policeManstringValues.new
        }
        listPath={ROUTE_PATHS.POLICEMAN_LIST}
      />
      <StyledFormContainer>
        <HelpComp />
        <Grid container rowSpacing={3} columnSpacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              label={"Name"}
              name={"name"}
              type={"text"}
              fullWidth
              value={values.name}
              isViewMode={isViewMode}
              onChange={(e, val) => {
                handleChange(e, val);
              }}
              formik={formik}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={"User Email"}
              name={"emailId"}
              isViewMode={isViewMode}
              value={values.emailId}
              onChange={handleChange}
              formik={formik}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={"Mobile No"}
              name={"mobileNo"}
              type={"mobile"}
              isViewMode={isViewMode}
              maxLength={10}
              fullWidth
              value={values.mobileNo}
              onChange={(e, val) => {
                handleChange(e, val);
              }}
              formik={formik}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomSelectField
              label={"Select Police Station"}
              name={"policeStationId"}
              fullWidth
              isViewMode={isViewMode}
              inputValues={station}
              value={values.policeStationId}
              onChange={(e, val) => {
                handleChange(e, val);
              }}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledButtonContainer>
              {!isViewMode && (
                <>
                  <CancelButton
                    variant="outlined"
                    sx={{ marginRight: "20px" }}
                    onClick={() => {
                      navigate(ROUTE_PATHS.POLICEMAN_LIST);
                    }}
                  >
                    {CANCEL}
                  </CancelButton>
                  <SubmitButton
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                    color="primary"
                  >
                    {editId ? UPDATE : SUBMIT}
                  </SubmitButton>
                </>
              )}
            </StyledButtonContainer>
          </Grid>
        </Grid>
      </StyledFormContainer>
    </Box>
  );
};

export default PoliceMenMasterForm;
