import { ROUTE_PATHS } from "../../routes/routePath";
import ComplaintList from "../pages/complaintListing/List";
import PoliceManList from "../pages/policeMenMaster/List";
import PsmList from "../pages/policeStationMaster/List";

export const adminSidebarMenus = [
  {
    id: 1,
    name: "POLICE STATION MASTER",
    routeKey: "policeStationMaster",
    route: ROUTE_PATHS.PSM_LIST,
    icon: <PsmList />,
  },
  {
    id: 2,
    name: "POLICEMAN MASTER",
    routeKey: "policeman",
    route: ROUTE_PATHS.POLICEMAN_LIST,
    icon: <PoliceManList />,
  },
  {
    id: 3,
    name: "COMPLAINT LIST",
    routeKey: "complaints",
    route: ROUTE_PATHS.COMPLAINT_LIST,
    icon: <ComplaintList />,
  },
];

export const nonAdminSidebarMenus = [
  {
    id: 1,
    name: "COMPLAINT LIST",
    routeKey: "complaints",
    route: ROUTE_PATHS.COMPLAINT_LIST,
    icon: <ComplaintList />,
  },
];
