import axios from "axios";

export const appApi = axios.create({

  // baseURL: "http://localhost:4001",
  baseURL:"https://student-safety.qa.casaretail.ai/",
  responseType: "json",
  headers: {
    Accept: "application/json",
    "content-type": "application/json",
  },
});
